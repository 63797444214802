<template>
  <div>
    <v-form @submit.prevent="save">
      <v-row>
        <!-- Jours Off -->
        <v-col cols="12" md="4">
          <h3>Jours de repos</h3>
          <v-date-picker
            full-width
            v-model="joursOff"
            color="blue-grey"
            :first-day-of-week="1"
            locale="fr-FR"
            multiple
          ></v-date-picker>
        </v-col>
        <v-col cols="12"
          ><v-btn type="submit" color="green" dark>Valider</v-btn></v-col
        >
      </v-row>
    </v-form>
    <!-- Snackbar -->
    <v-snackbar v-model="snackbar">
      Sauvegardé avec succès !

      <template v-slot:action="{ attrs }">
        <v-btn
          :loading="loading"
          color="green"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { db } from "../../main";
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  data() {
    return {
      loading: false,
      snackbar: false,
      joursOff: [],
      form: {
        joursOff: [],
      },
    };
  },

  mounted() {
    this.$emit("setTitre", "Paramètres");
    this.form = { ...this.params };

    // Convertire le format des jours Off
    this.params.joursOff.forEach((element) => {
      const date = dayjs(element, "DD/MM/YYYY");
      this.joursOff.push(date.format("YYYY-MM-DD"));
    });
  },

  computed: {
    params() {
      return this.$store.state.params;
    },
  },

  methods: {
    async save() {
      this.loading = true;
      let formattedForm = { ...this.form };
      formattedForm.joursOff = [];
      this.joursOff.forEach((jour) => {
        const date = dayjs(jour);
        if (date.isSame(dayjs(), "date") || date.isAfter(dayjs())) {
          formattedForm.joursOff.push(date.format("DD/MM/YYYY"));
        }
      });
      await db.doc("parametres/parametres").update(formattedForm);
      this.snackbar = true;
      this.loading = false;
    },
  },
};
</script>

<style>
</style>
