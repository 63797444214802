<template>
  <div>
    <h2 class="mt-10">Infos de contact</h2>
    <v-row>
      <v-col cols="12" md="6">
        <InfoItem titre="Email" :valeur="consultation.email" />
      </v-col>
      <v-col cols="12" md="6">
        <InfoItem titre="Téléphone" :valeur="consultation.telephone" />
      </v-col>
    </v-row>

    <h2 class="mt-10 mb-5">Changements à apporter au dossier médical</h2>

    <div class="my-5 font-italic" v-if="consultation.changements.length == 0">
      Pas de changements à apporter
    </div>
    <div v-else>
      <div v-for="(changement, index) in consultation.changements" :key="index">
        <InfoItem
          :titre="consultation.infosAChanger[index]"
          :valeur="changement"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import dayjs from "dayjs";
import InfoItem from "../../../components/InfoItem";

export default {
  components: {
    InfoItem,
  },

  props: {
    consultation: Object,
  },

  mounted() {},

  computed: {},
};
</script>
