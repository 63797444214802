<template>
  <v-dialog v-model="show" persistent max-width="380">
    <v-card>
      <v-card-title class="headline"> Confirmation </v-card-title>
      <v-card-text>Êtes-vous sûr ?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="$emit('fermer')"> Annuler </v-btn>
        <v-btn
          color="red darken-1"
          text
          @click="$emit('confirmer')"
          :loading="loading"
        >
          Confirmer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    loading: Boolean,
  },
};
</script>

