<template>
  <div>
    <v-btn depressed @click="$router.push({ name: 'planning' })"
      ><v-icon class="mr-2">mdi-arrow-left</v-icon> Retour</v-btn
    >
    <v-btn class="ml-4" color="orange" dark depressed @click="dialogDate = true"
      ><v-icon class="mr-2">mdi-calendar</v-icon> Modifier la date</v-btn
    >
    <v-btn class="ml-4" color="red" dark depressed @click="dialogDelete = true"
      ><v-icon class="mr-2">mdi-delete</v-icon> Supprimer le RDV</v-btn
    >
    <!-- LOADING  -->
    <v-container
      class="d-flex justify-center align-center"
      style="height: 400px"
      v-if="loading"
    >
      <v-progress-circular
        indeterminate
        color="blue"
        size="55"
      ></v-progress-circular>
    </v-container>

    <!-- NOT LOADING  -->
    <div v-else>
      <div class="mt-8" style="font-size: 12px">
        {{ dateFormatee(consultation.debut) }}
      </div>
      <h1 class="font-weight-black">
        {{ consultation.nom.toUpperCase() }} {{ consultation.prenom }}
      </h1>
      <div class="font-italic mb-8">
        {{ consultation.nouveauPatient ? "Nouveau patient" : "Déjà venu" }}
      </div>

      <h2>Motif de consultation</h2>
      <InfoItem titre="Motif de consultation" :valeur="consultation.motif" />
      <InfoItem
        titre="Articulation concernée"
        :valeur="consultation.articulationConcernee"
        v-if="consultation.articulationConcernee"
      />
      <InfoItem
        titre="Terme"
        :valeur="consultation.terme"
        v-if="consultation.terme"
      />
      <InfoItem
        titre="Zone concernée"
        :valeur="consultation.zoneConcernee"
        v-if="consultation.zoneConcernee"
      />
      <InfoItem
        titre="Muscle ou zone concerné"
        :valeur="consultation.muscleOuZoneConcernee"
        v-if="consultation.muscleOuZoneConcernee"
      />
      <InfoItem
        titre="Date de l'entorse"
        :valeur="dateEntorse"
        v-if="consultation.dateEntorse"
      />
      <InfoItem
        titre="Le motif :"
        :valeur="consultation.autreMotif"
        v-if="consultation.autreMotif"
      />

      <div
        v-if="
          consultation.motif != 'Checkup / Suivi' &&
          consultation.motif !=
            'Suite de consultation il y a moins de 2 semaines'
        "
      >
        <h2 class="mt-10 mb-7">Description du motif de consultation</h2>

        <InfoItem
          titre="Depuis quand les symptômes sont-ils apparus?"
          :valeur="consultation.depuisQuand"
        />
        <InfoItem
          titre="Est-ce la première fois que vous les ressentez? Si non, cet épisode est-il différent des précédents et en quoi?"
          :valeur="consultation.premiereFois"
        />
        <InfoItem
          titre="Qu’est-ce qui a provoqué vos douleurs? Y a t-il un élément déclencheur à cet épisode?"
          :valeur="consultation.elementDeclencheur"
        />
        <InfoItem
          titre="Comment s’est installé cet épisode?"
          :valeur="consultation.installation"
        />
        <InfoItem
          titre="Où se localise la douleur?"
          :valeur="consultation.localisationDouleur"
        />
        <InfoItem
          titre="Diffuse- t-elle ailleurs?"
          :valeur="consultation.diffuse"
        />
        <InfoItem
          titre="Existe-t-il des mouvements, des positionnements ou des facteurs aggravant votre douleur?"
          :valeur="consultation.mouvementsAggravants"
        />
        <InfoItem
          titre="Existe-t-il des mouvements, des positionnements ou des facteurs soulageant votre douleur?"
          :valeur="consultation.mouvementsQuiSoulagent"
        />
        <InfoItem
          titre="Cette douleur vous réveille-t-elle la nuit? Si oui, tant la douleur est intense? ou lorsque vous bougez?"
          :valeur="consultation.nuit"
        />
        <InfoItem
          titre="Veuillez évaluer cette douleur sur 10 svp. (0= absence de gêne, 10= douleur intolérable)"
          :valeur="consultation.evaluationDouleur"
        />
        <InfoItem
          titre="Avez-vous essayé un traitement, une crème, un médicament pour atténuer les choses?"
          :valeur="consultation.medicament"
        />
        <InfoItem
          titre="Les douleurs sont-elles: De pire en pire? Stable? En train de disparaître?"
          :valeur="consultation.evolutionDouleurs"
        />
        <InfoItem
          titre="Les douleurs sont-elles plus intenses le matin? Le soir? La nuit? Au travail? Au repos? Le week-end?"
          :valeur="consultation.momentDouleurs"
        />
        <InfoItem
          titre="Les douleurs sont-elles permanentes ou intermittentes?"
          :valeur="consultation.douleursPermanentesOuIntermitentes"
        />
        <InfoItem
          titre="Quel est le type de douleur? ça pique? ça lance? Ça tord? ça serre? c’est un poids?"
          :valeur="consultation.typeDouleur"
        />
        <InfoItem
          titre="Ressentez-vous des symptômes que vous associez à cet épisode?"
          :valeur="consultation.symptomes"
        />
        <InfoItem
          titre="Trouvez-vous une modification de votre état global en ce moment? Une fatigue, une perte de poids ou d’appétit inexpliqués ?"
          :valeur="consultation.modificationEtatGlobal"
        />
        <InfoItem
          titre="Voyez-vous des signes d’inflammation sur la zone douloureuse? Chaleur - rougeur - gonflement"
          :valeur="consultation.inflammation"
        />
      </div>

      <!-- Spécial femme enceinte  -->
      <div v-if="consultation.motif == 'Suivi de grossesse'">
        <InfoItem
          titre="Quel est le terme de la grossesse? Combien de kilos avez-vous pris ou perdus depuis le début de votre grossesse?"
          :valeur="consultation.termeGrossesse"
        />
        <InfoItem
          titre="Avez-vous d’autres manifestations ? Hypertension artérielle, diabète gestationnel, constipation, hémorroïdes, oedèmes ..."
          :valeur="consultation.autresManifestations"
        />
        <InfoItem
          titre="Date de la dernière consultation avec votre sage-femme ou gynécologue, et bilan de cette consultation."
          :valeur="consultation.derniereConsultation"
        />
        <InfoItem
          titre="Dernière échographie et bilan de cet examen"
          :valeur="consultation.derniereEchographie"
        />
      </div>

      <!-- Suite selon type de patient  -->
      <NouveauPatient
        :consultation="consultation"
        v-if="consultation.nouveauPatient"
      />
      <AncienPatient :consultation="consultation" v-else />
      <v-dialog v-model="dialogDate" width="800">
        <ModifDate @fermer="fermerDate" />
      </v-dialog>
    </div>

    <!-- Dialog confirmation de suppression  -->
    <ConfirmationDialog
      @fermer="fermerDeleteDialog"
      @confirmer="deleteRDV"
      :show.sync="dialogDelete"
      :loading.sync="loadingDelete"
    />
  </div>
</template>

<script>
import { db } from "../../../main";
import AncienPatient from "./AncienPatient";
import NouveauPatient from "./NouveauPatient";
import ModifDate from "./ModifDate";

import dayjs from "dayjs";
require("dayjs/locale/fr");
dayjs.locale("fr");
import InfoItem from "../../../components/InfoItem";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

export default {
  components: {
    AncienPatient,
    NouveauPatient,
    InfoItem,
    ModifDate,
    ConfirmationDialog,
  },

  data() {
    return {
      loading: true,
      consultation: {},
      dialogDate: false,
      dialogDelete: false,
      loadingDelete: false,
    };
  },

  mounted() {
    this.$emit("setTitre", "Détails du RDV");
    this.getRDV();
  },

  computed: {
    dateEntorse() {
      return dayjs(this.consultation.dateEntorse, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      );
    },
  },

  methods: {
    async deleteRDV() {
      this.loadingDelete = true;
      const id = this.$route.params.id;
      await db.collection("consultations").doc(id).delete();
      this.fermerDeleteDialog();
      this.$router.push({ name: "planning" });
    },

    fermerDate() {
      this.dialogDate = false;
      this.getRDV();
    },

    fermerDeleteDialog() {
      this.dialogDelete = false;
    },

    dateFormatee(date) {
      return dayjs(date.toDate()).format("DD/MM/YYYY à HH:mm");
    },

    async getRDV() {
      this.loading = true;
      const id = this.$route.params.id;
      const snap = await db.collection("consultations").doc(id).get();
      this.consultation = snap.data();
      this.loading = false;
    },
  },
};
</script>
