<template>
  <div>
    <form class="mt-8 v-form" @submit.prevent="save">
      <v-row>
        <!-- Jour  -->
        <v-col cols="12" md="4">
          <v-select
            label="Jour"
            outlined
            :items="joursItems"
            v-model="form.jour"
            required
          >
          </v-select>
        </v-col>

        <!-- Durée -->
        <v-col cols="6" md="2">
          <v-text-field
            label="Durée"
            v-model="form.duree"
            type="number"
            step="1"
            outlined
            required
          ></v-text-field>
        </v-col>

        <!-- Remplacant -->
        <v-col cols="12" md="5">
          <v-switch v-model="form.remplacant" label="Remplaçant"></v-switch>
        </v-col>

        <!-- Heure de début  -->
        <v-col cols="12" md="3">
          <div class="font-weight-bold mb-3">Heure de début</div>
          <div class="d-flex">
            <v-text-field
              label="Heures"
              v-model="form.debut.heures"
              type="number"
              step="1"
              outlined
              required
            ></v-text-field>
            <div class="mx-3 align-self-center">h</div>
            <v-text-field
              label="Minutes"
              v-model="form.debut.minutes"
              type="number"
              step="1"
              outlined
              required
            ></v-text-field>
          </div>
        </v-col>

        <!-- Bouton submit  -->
        <v-col cols="12">
          <div class="d-flex">
            <v-spacer></v-spacer>
            <v-btn color="green" large dark type="submit">Valider</v-btn>
          </div>
        </v-col>
      </v-row>
    </form>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    mode: String,
    creneau: Object
  },

  data() {
    return {
      joursItems: [
        {
          text: "Lundi",
          value: 1
        },
        {
          text: "Mardi",
          value: 2
        },
        {
          text: "Mercredi",
          value: 3
        },
        {
          text: "Jeudi",
          value: 4
        },
        {
          text: "Vendredi",
          value: 5
        },
        {
          text: "Samedi",
          value: 6
        },
        {
          text: "Dimanche",
          value: 7
        }
      ],
      form: {
        jour: null,
        remplacant: false,
        debut: {
          heures: null,
          minutes: null
        },
        duree: 45
      }
    };
  },

  mounted() {
    if (this.mode === "edit") {
      this.form = { ...this.creneau };
    }
  },

  methods: {
    save() {
      if (!this.form.jour) return;
      let formattedForm = { ...this.form };
      formattedForm.debut.minutes = Number(this.form.debut.minutes);
      formattedForm.debut.heures = Number(this.form.debut.heures);
      formattedForm.duree = Number(this.form.duree);
      this.$emit("save", formattedForm);
      this.$router.push({ name: "creneaux" });
    }
  }
};
</script>

<style>
.switches .v-input--selection-controls {
  margin-top: 0;
}
</style>
