<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" lg="3" v-for="jour in jours" :key="jour">
        <h3 class="mb-3">{{ nomJours[jour - 1] }}</h3>
        <div
          v-for="creneau in creneaux.filter((c) => c.jour == jour)"
          :key="creneau.id"
        >
          <div
            class="lighten-1 pa-3 white--text rounded mb-2 d-flex"
            style="cursor: pointer"
            :class="creneau.remplacant ? 'deep-orange' : 'teal'"
            @click="editCreneau(creneau.id)"
          >
            <div>
              <div class="font-weight-bold">
                {{ creneau.debut.heures }}h{{
                  creneau.debut.minutes.toString().length == 1
                    ? "0" + creneau.debut.minutes
                    : creneau.debut.minutes
                }}
              </div>
              <div class="font-italic">Durée: {{ creneau.duree }} min</div>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="confirmerSupp(creneau.id)"
              ><v-icon color="white">mdi-delete-forever</v-icon></v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <v-btn fab dark right color="green" absolute bottom @click="createCreneau">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <ConfirmationDialog
      @fermer="fermer"
      @confirmer="deleteCreneau"
      :show.sync="showDeleteModal"
      :loading.sync="loadingDelete"
    />
  </div>
</template>

<script>
import { db } from "../../../main";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
export default {
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      selectedCreneau: null,
      showDeleteModal: false,
      loadingDelete: false,
      jours: [1, 2, 3, 4, 5, 6, 7],
      nomJours: [
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
        "Dimanche",
      ],
    };
  },

  computed: {
    creneaux() {
      return this.$store.state.creneaux;
    },
  },

  mounted() {
    this.$emit("setTitre", "Créneaux de consultations");
  },

  methods: {
    confirmerSupp(id) {
      this.selectedCreneau = id;
      this.showDeleteModal = true;
    },
    fermer() {
      this.showDeleteModal = false;
      this.selectedCreneau = null;
    },
    async deleteCreneau() {
      this.loadingDelete = true;
      await db.collection("creneaux").doc(this.selectedCreneau).delete();
      this.loadingDelete = false;
      this.fermer();
    },
    createCreneau() {
      this.$router.push({ name: "creneaux.create" });
    },
    editCreneau(id) {
      this.$router.push({ name: "creneaux.edit", params: { id: id } });
    },
  },
};
</script>

<style>
</style>
