<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Aujourd'hui
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Journée</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semaine</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mois</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 jours</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="700">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="consultations"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
        >
        </v-calendar>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from "dayjs";
require("dayjs/locale/fr");
dayjs.locale("fr");

export default {
  components: {},

  data() {
    return {
      today: dayjs().format("YYYY-MM-DD"),
      focus: "",
      type: "4day",
      typeToLabel: {
        month: "Mois",
        week: "Semaine",
        day: "Journée",
        "4day": "4 jours",
      },
    };
  },

  computed: {
    consultations() {
      return this.$store.state.consultations.map((e) => ({
        id: e.id,
        name: e.prenom + " " + e.nom.toUpperCase(),
        start: dayjs(e.debut.toDate()).format("YYYY-MM-DD HH:mm"),
        end: dayjs(e.fin.toDate()).format("YYYY-MM-DD HH:mm"),
        color: e.nouveauPatient ? "orange" : "blue",
      }));
    },
  },

  mounted() {
    this.$emit("setTitre", "Planning");
    this.$refs.calendar.checkChange();
    this.$refs.calendar.scrollToTime("07:30");
  },

  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const id = event.id;
      this.$router.push({ name: "fiche-rdv", params: { id } });

      nativeEvent.stopPropagation();
    },

    formatDate(date) {
      return dayjs(date).format("dddd DD MMMM YYYY à HH:mm");
    },

    isSameDay(date) {
      return dayjs(date).isSame(dayjs(), "day");
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer:hover {
  cursor: pointer;
}
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>

