<template>
  <div>
    <v-btn depressed @click="$router.push({ name: 'creneaux' })"
      ><v-icon class="mr-2">mdi-arrow-left</v-icon> Retour</v-btn
    >
    <v-form></v-form>

    <FormulaireCreneau mode="create" @save="createCreneau" />
  </div>
</template>

<script>
import FormulaireCreneau from "./FormulaireCreneau.vue";
import { db } from "../../../main";

export default {
  components: {
    FormulaireCreneau,
  },

  mounted() {
    this.$emit("setTitre", "Nouveau créneau de RDV");
  },

  methods: {
    async createCreneau(form) {
      await db.collection("creneaux").add(form);
    },
  },
};
</script>

