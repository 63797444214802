<template>
  <div>
    <v-btn depressed @click="$router.push({ name: 'creneaux' })"
      ><v-icon class="mr-2">mdi-arrow-left</v-icon> Retour</v-btn
    >

    <FormulaireCreneau
      v-if="!loading"
      :creneau="creneau"
      mode="edit"
      @save="updateCreneau"
    />
  </div>
</template>

<script>
import FormulaireCreneau from "./FormulaireCreneau";
import { db } from "../../../main";

export default {
  components: {
    FormulaireCreneau,
  },

  data() {
    return {
      loading: true,
      creneau: null,
    };
  },

  mounted() {
    this.$emit("setTitre", "Modifier le creneau");
    this.getCreneau();
  },

  methods: {
    async getCreneau() {
      const id = this.$route.params.id;
      const snap = await db.collection("creneaux").doc(id).get();
      this.creneau = snap.data();
      this.loading = false;
    },

    async updateCreneau(form) {
      const id = this.$route.params.id;
      await db.collection("creneaux").doc(id).update(form);
    },
  },
};
</script>
