<template>
  <div class="grey lighten-4 pa-3 my-2 mt-5 rounded">
    <div class="mb-1 font-weight-bold">
      <v-icon class="mb-1 mr-3">mdi-arrow-right-circle</v-icon>
      {{ titre }}
    </div>
    <div>{{ valeur }}</div>
  </div>
</template>

<script>
export default {
  props: {
    titre: String,
    valeur: String,
  },
};
</script>
