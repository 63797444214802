<template>
  <div>
    <h2 class="mt-10 mb-5">Dossier médical</h2>

    <!-- Etat civil  -->
    <h3>Infos. administratives</h3>
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <InfoItem titre="Sexe" :valeur="consultation.sexe" />
      </v-col>
      <v-col cols="12" md="5" lg="4">
        <InfoItem
          titre="Date de naissance"
          :valeur="formatDate(consultation.dateDeNaissance)"
        />
      </v-col>
      <v-col cols="12" md="5" lg="4">
        <InfoItem
          titre="Situation maritale"
          :valeur="consultation.situationMaritale"
        />
      </v-col>
      <v-col cols="12" md="5" lg="4">
        <InfoItem titre="Téléphone" :valeur="consultation.telephone" />
      </v-col>
      <v-col cols="12" md="5" lg="4">
        <InfoItem titre="Adresse" :valeur="consultation.adresse" />
      </v-col>
      <v-col cols="12" md="5" lg="4">
        <InfoItem titre="Email" :valeur="consultation.email" />
      </v-col>
      <v-col cols="12" md="5" lg="4">
        <InfoItem titre="Taille" :valeur="consultation.taille" />
      </v-col>
      <v-col cols="12" md="5" lg="4">
        <InfoItem titre="Poids" :valeur="consultation.poids" />
      </v-col>
      <v-col cols="12" md="6">
        <InfoItem
          titre="Médecin traitant"
          :valeur="consultation.medecinTraitant"
        />
      </v-col>
      <v-col cols="12" md="6">
        <InfoItem
          titre="Emploi ou études actuels"
          :valeur="consultation.emploi"
        />
      </v-col>
      <v-col cols="12">
        <InfoItem
          titre="Emplois précédents"
          :valeur="consultation.emploisPrecedents"
        />
      </v-col>
      <!-- / Etat civil  -->

      <!-- Traitements en cours  -->
      <v-col cols="12"><h3>Traitements en cours</h3></v-col>
      <v-col cols="12">
        <InfoItem titre="Medicamenteux" :valeur="consultation.medicamenteux" />
      </v-col>
      <v-col cols="12">
        <InfoItem
          titre="Dispositifs médicaux"
          :valeur="consultation.dispositifsMedicaux"
        />
      </v-col>
    </v-row>
    <!-- / Traitements en cours  -->

    <!-- Partie spéciale femmes  -->
    <v-row v-if="consultation.sexe == 'Femme'">
      <v-col cols="12" md="4">
        <InfoItem
          titre="Prise de contraception ?"
          :valeur="consultation.prendContraception"
        />
      </v-col>
      <v-col cols="12" md="8" v-if="consultation.prendContraception == 'Oui'">
        <InfoItem titre="Laquelle ?" :valeur="consultation.contraception" />
      </v-col>
      <v-col cols="12" md="8" v-if="consultation.prendContraception == 'Non'">
        <InfoItem
          titre="Pourquoi ?"
          :valeur="consultation.raisonNonContraception"
        />
      </v-col>
      <v-col cols="12" v-if="consultation.dateDernieresRegles">
        <InfoItem
          titre="Date de vos dernières règles ?"
          :valeur="formatDate(consultation.dateDernieresRegles)"
        />
      </v-col>
    </v-row>
    <!-- / Partie spéciales femmes  -->

    <!-- Habitudes de vie  -->
    <v-row>
      <v-col cols="12"><h3>Habitudes de vie</h3></v-col>
      <v-col cols="12" md="6">
        <InfoItem
          titre="Entreprise ou école"
          :valeur="consultation.entreprise"
        />
      </v-col>
      <v-col cols="12" md="6">
        <InfoItem titre="Sports actuels" :valeur="consultation.sportsActuels" />
      </v-col>
      <v-col cols="12" md="6">
        <InfoItem titre="Sports passés" :valeur="consultation.sportsPasses" />
      </v-col>
      <v-col cols="12" md="6">
        <InfoItem titre="Loisirs, hobbies" :valeur="consultation.loisirs" />
      </v-col>
      <v-col cols="12" md="6">
        <InfoItem
          titre="Droitier/gaucher"
          :valeur="consultation.droitierGaucher"
        />
      </v-col>
      <!-- / Habitudes de vie  -->

      <!-- Alimentation et hydratation  -->
      <v-col cols="12"><h3>Alimentation et hydratation</h3></v-col>
      <v-col cols="12" md="6">
        <InfoItem
          titre="Type d'alimentation"
          :valeur="consultation.typeAlimentation"
        />
      </v-col>
      <v-col cols="12" md="6">
        <InfoItem
          titre="Types de boissons"
          :valeur="consultation.typeBoissons"
        />
      </v-col>
      <v-col cols="12" md="6">
        <InfoItem
          titre="Suivi auprès d'un(e) diet ?"
          :valeur="consultation.suiviDiet"
        />
      </v-col>
      <v-col cols="12" md="6">
        <InfoItem
          titre="Variation importante de poids dans sa vie"
          :valeur="consultation.variationsImportantesPoids"
        />
      </v-col>
      <!-- / Alimentation et hydratation  -->

      <!-- Antécédents chirurgicaux -->
      <v-col cols="12"><h3>Antécédents chirurgicaux</h3></v-col>
      <v-col cols="12" md="6">
        <InfoItem
          titre="Opérations subies"
          :valeur="lister(consultation.operationsFaites)"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="consultation.datesOperations">
        <InfoItem
          titre="Dates des opérations"
          :valeur="consultation.datesOperations"
        />
      </v-col>
      <!-- / Antécédents chirurgicaux -->

      <!-- Antécédents traumatiques -->
      <v-col cols="12"><h3>Antécédents traumatiques</h3></v-col>
      <v-col cols="12" md="6">
        <InfoItem
          titre="Traumatismes subis"
          :valeur="lister(consultation.traumatismesSubis)"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="consultation.datesTraumatismes">
        <InfoItem
          titre="Dates des traumatismes"
          :valeur="consultation.datesTraumatismes"
        />
      </v-col>
      <!-- / Antécédents traumatiques -->

      <!-- Antécédents médicaux -->
      <v-col cols="12"><h3>Antécédents médicaux</h3></v-col>
      <v-col cols="12" md="6">
        <InfoItem
          titre="Problèmes/événements médicaux"
          :valeur="lister(consultation.antecedentsMedicaux)"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="consultation.agePremieresRegles">
        <InfoItem
          titre="Age des premières règles"
          :valeur="consultation.agePremieresRegles"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="consultation.ageMenopause">
        <InfoItem
          titre="Age de la ménopause"
          :valeur="consultation.ageMenopause"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="consultation.sexe == 'Femme'">
        <InfoItem
          titre="Vaccin Gardasil®️ fait ?"
          :valeur="consultation.vaccinGardasilFait ? 'Oui' : 'Non'"
        />
      </v-col>
      <!-- / Antécédents médicaux -->

      <!-- Histoire familiale -->
      <v-col cols="12"><h3>Histoire familiale</h3></v-col>
      <v-col cols="12">
        <InfoItem
          titre="Comment s'est passée la grossesse de la mère"
          :valeur="consultation.grossesseMere"
        />
      </v-col>
      <v-col cols="12" md="3">
        <InfoItem
          titre="Des enfants ?"
          :valeur="consultation.enfants ? 'Oui' : 'Non'"
        />
      </v-col>
      <v-col cols="12" md="9" v-if="consultation.enfants">
        <InfoItem
          titre="Détails sur les enfants"
          :valeur="consultation.detailsEnfants"
        />
      </v-col>
      <v-col cols="12">
        <InfoItem
          titre="Pathologies dans la famille"
          :valeur="consultation.pathologiesMembresFamille"
        />
      </v-col>
      <!-- / Histoire familiale -->

      <!-- Examens complémentaires  -->
      <v-col cols="12"><h3>Examens complémentaires</h3></v-col>
      <v-col cols="12" md="6">
        <InfoItem
          titre="Examens passés"
          :valeur="lister(consultation.examensComplementaires)"
        />
      </v-col>
      <v-col cols="12" md="6">
        <InfoItem titre="Résultats" :valeur="consultation.detailsExamens" />
      </v-col>
      <!-- / Examens complémentaires  -->

      <!-- Ostéopathie  -->
      <v-col cols="12"><h3>Ostéopathie</h3></v-col>
      <v-col cols="12" md="6">
        <InfoItem
          titre="Membre de la famille ayant déjà consulté ? Qui ?"
          :valeur="consultation.membresFamilleDejaConsulte"
        />
      </v-col>
      <v-col cols="12" md="6">
        <InfoItem
          titre="Comment avez-vous connu le cabinet ?"
          :valeur="consultation.commentConnuCabinet"
        />
      </v-col>
      <v-col cols="12">
        <InfoItem
          titre="Déjà consulté ostéo ? Qui ? Pourquoi ? Quand ?"
          :valeur="consultation.dejaConsulteOsteo"
        />
      </v-col>
      <!-- / Ostéopathie  -->
    </v-row>
  </div>
</template>

<script>
import dayjs from "dayjs";
import InfoItem from "../../../components/InfoItem";

export default {
  components: {
    InfoItem,
  },

  props: {
    consultation: Object,
  },

  mounted() {},

  computed: {},

  methods: {
    lister(liste) {
      let res = "";
      liste.forEach((item) => (res = res + item + ", "));
      if (res == "") return "Rien à afficher";
      return res;
    },
    formatDate(date) {
      return dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
  },
};
</script>
